import useTundraStore from "../../store/useTundraStore";
import useChanges from "../../hooks/useChanges";
import React, { useMemo } from "react";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ChannelType } from "../../types/ChannelType";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { LoadingOrErrorContainer } from "../../components/EmissionsCalendar/EmissionsCalendar";
import useBroadcasts from "../../hooks/useBroadcasts";
import { getEventColorsAndIcon } from "../../utils/getEventColorsAndIcon";
import getBroadcastUIType from "../../utils/getBroadcastUIType";
import getBroadcastShortLabel from "../../utils/getBroadcastShortLabel";
import { AddCircleOutlined } from "@mui/icons-material";
import { HistoricChange } from "../../types/HistoricChange";
import HistoricChangesModal from "./HistoricChangesModal";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Change } from "../../types/Change";

const ChangesTable = ({
  typesOfChanges,
  broadcastTypes,
}: {
  typesOfChanges: number[];
  broadcastTypes: number[];
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const searchString = useTundraStore((state) => state.searchString);

  const [selectedChanges, setSelectedChanges] = React.useState<
    HistoricChange[] | null
  >(null);

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "changeDate", headerName: "Change Date", width: 110 },
      { field: "broadcastDate", headerName: "Broadcast Date", width: 130 },
      {
        field: "timing",
        headerName: "Timing",
        width: 120,
        sortable: false,
        renderCell: (params: GridRenderCellParams<[string, string]>) => {
          return (
            <Box>
              <Typography
                sx={{ textDecoration: "line-through", display: "block" }}
              >
                {params.value[0]}
              </Typography>
              <Typography sx={{ display: "block" }}>
                {params.value[1]}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "valEmiId",
        headerName: "ValEmiId",
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Change, number>) => {
          return (
            <Tooltip
              title={
                <Box color="primary.main" sx={{ fontSize: "16px" }}>
                  <Typography>
                    <strong>Program Id:</strong>{" "}
                    {params.row.programId || "none"}
                  </Typography>
                  <Typography>
                    <strong>SPEV Id:</strong> {params.row.spevId || "none"}
                  </Typography>
                  <Typography>
                    <strong>Broadcast Id:</strong>{" "}
                    {params.row.broadcastId || "none"}
                  </Typography>
                </Box>
              }
            >
              <Typography>{params.value}</Typography>
            </Tooltip>
          );
        },
      },
      { field: "channel", headerName: "Channel", width: 220 },
      { field: "type", headerName: "Type", width: 140 },
      { field: "program", headerName: "Program", flex: 2, sortable: false },
      {
        field: "broadcastType",
        headerName: "Broadcast Type",
        width: 140,
        sortable: false,
        renderCell: (params: GridRenderCellParams<String>) => {
          const [backgroundColor, accentColor] = getEventColorsAndIcon(
            getBroadcastUIType(params.value)
          );
          const label = getBroadcastShortLabel(params.value);
          return (
            <Box sx={{ backgroundColor, p: "5px 8px", borderRadius: "5px" }}>
              <Typography color={accentColor} fontWeight={700}>
                {label}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "historicChanges",
        width: 70,
        headerName: "",
        renderCell: (params: GridRenderCellParams<HistoricChange[]>) => {
          if (params.value.length === 0) return null;
          return (
            <IconButton onClick={() => setSelectedChanges(params.value)}>
              <AddCircleOutlined />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const changesQuery = useChanges(
    pickerStartDate,
    pickerEndDate,
    uiChannels
      .filter((channel) => channel.type === ChannelType.Channel)
      .map(({ id }) => id),
    typesOfChanges,
    broadcastTypes
  );

  const broadcastsQuery = useBroadcasts();

  if (changesQuery.isLoading || broadcastsQuery.isLoading) {
    return (
      <LoadingOrErrorContainer>
        <CircularProgress />
      </LoadingOrErrorContainer>
    );
  }

  if (
    changesQuery.error ||
    !changesQuery.data ||
    broadcastsQuery.error ||
    !broadcastsQuery.data
  )
    return (
      <LoadingOrErrorContainer>
        {"An error has occurred"}
      </LoadingOrErrorContainer>
    );

  const changesRows = changesQuery.data.histoChanges
    .map((change) => ({
      id: change.changeDate + change.broadcastId,
      changeDate: change.changeDate,
      broadcastDate: change.broadcastDate,
      timing: [change.oldTiming, change.newTiming],
      valEmiId: change.valEmiId,
      channel: change.channel,
      type: change.typeOfChange,
      program: change.program,
      broadcastTypeImage: change.broadcastPicture,
      broadcastType: change.broadcastingTypeId,
      historicChanges: change.historicChanges || [],
      broadcastId: change.broadcastId,
      programId: change.programId,
      spevId: change.spevId,
    }))
    .filter((row) =>
      row.program.toLowerCase().includes(searchString.toLowerCase())
    );

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "96px",
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "background.default",
        }}
      >
        <DataGrid
          rows={changesRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "background.paper",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "gray.600",
              fontSize: "12px",
              fontWeight: 700,
              textTransform: "uppercase",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "14px",
              color: "text.primary",
            },
          }}
        />
      </Box>
      {selectedChanges && (
        <HistoricChangesModal
          changes={selectedChanges}
          onClose={() => setSelectedChanges(null)}
        />
      )}
    </>
  );
};

export default ChangesTable;
