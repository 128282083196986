import Dialog from "@mui/material/Dialog";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Add, Close, Subscriptions } from "@mui/icons-material";
import { useState } from "react";
import useChangesSubscriptions from "../../../hooks/useChangesSubscriptions";
import ChangesSubscriptionsTable from "./ChangesSubscriptionsTable";
import SubscriptionEditor from "./SubscriptionEditor";
import { ChangesSubscription } from "../../../types/ChangesSubscription";
import useDeleteChangesSubscription from "../../../hooks/useDeleteChangesSubscription";

const createNewSubscription = (): ChangesSubscription => ({
  id: null,
  title: "",
  email: "",
  range: 0,
  channelIds: [],
  broadcastTypeIds: [],
  typeOfChangeIds: [],
});

const SubscriptionsModal = ({ onClose }: { onClose: () => void }) => {
  const subscriptionsQuery = useChangesSubscriptions();
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(
    createNewSubscription()
  );
  const deleteChangesMutation = useDeleteChangesSubscription();

  const deleteSubscription = (id: number) => {
    deleteChangesMutation.mutate(id, {
      onSuccess: () => {
        subscriptionsQuery.refetch();
      },
    });
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Subscriptions
          <IconButton onClick={onClose}>
            <Close sx={{ color: "primary.main", fontSize: "22px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setEditorOpen(true);
              setCurrentSubscription(createNewSubscription());
            }}
          >
            Add Subscription
          </Button>
          {isEditorOpen && (
            <SubscriptionEditor
              onSuccess={() => {
                setEditorOpen(false);
                subscriptionsQuery.refetch();
              }}
              onClose={() => setEditorOpen(false)}
              data={currentSubscription}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
            {subscriptionsQuery.isLoading && (
              <Box sx={{ marginTop: "50px", alignSelf: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {deleteChangesMutation.isError && (
              <Alert severity="error">Error deleting subscription</Alert>
            )}
            {subscriptionsQuery.data && (
              <ChangesSubscriptionsTable
                data={subscriptionsQuery.data}
                onSelect={(data) => {
                  setCurrentSubscription(data);
                  setEditorOpen(true);
                }}
                onDelete={deleteSubscription}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ChangesSubscriptionsModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        sx={{ minWidth: "180px" }}
        startIcon={<Subscriptions />}
        onClick={() => setIsOpen(true)}
      >
        Subscriptions
      </Button>
      {isOpen && <SubscriptionsModal onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default ChangesSubscriptionsModal;
