import * as React from "react";
import { Header } from "@shared/eurosport-ui";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Switch,
  FormControlLabel,
} from "@mui/material";
import WrapTextOutlinedIcon from "@mui/icons-material/WrapTextOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LogIn from "../LogIn/LogIn";
import { ReactNode } from "react";
import useTundraStore from "../../store/useTundraStore";

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
const DARK_THEME_BACKGROUND_COLOR = "#24232A";

const TundraAppBar = ({
  pageName,
  innerComponent,
}: {
  pageName: string;
  innerComponent?: ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const colorMode = useTundraStore((state) => state.colorMode);
  const setColorMode = useTundraStore((state) => state.setColorMode);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  return (
    <>
      <Box sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 30 }}>
        <Header
          appName="Tundra"
          pageName={pageName}
          env={REACT_APP_ENVIRONMENT}
          onMenuClick={() => {
            setIsOpen(true);
          }}
          rightComponent={
            <>
              {innerComponent}
              <LogIn />
            </>
          }
        />
      </Box>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          width="295px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          role="presentation"
          position="relative"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              left: "0",
              bottom: "60px",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor:
                colorMode === "dark" ? DARK_THEME_BACKGROUND_COLOR : "inherit",
            }}
          >
            <List>
              <ListItem
                disablePadding
                sx={{
                  backgroundImage: "none",
                  backgroundColor:
                    colorMode === "dark"
                      ? DARK_THEME_BACKGROUND_COLOR
                      : "inherit",
                }}
              >
                <Link href={`/`} sx={{ width: "100%", textDecoration: "none" }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <CalendarTodayIcon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Schedule"
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 700,
                        },
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  href={`/changes`}
                  sx={{ width: "100%", textDecoration: "none" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <WrapTextOutlinedIcon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Changes"
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 700,
                        },
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link
                  href={`/grid`}
                  sx={{ width: "100%", textDecoration: "none" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <GridViewOutlinedIcon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Grid"
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 700,
                        },
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Box>
          <Box
            sx={{
              padding: "8px 24px",
              borderTop: "1px solid",
              borderColor: "divider",
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
              height: "60px",
            }}
          >
            <FormControlLabel
              control={<Switch />}
              checked={colorMode === "dark"}
              label="Dark Mode"
              color="primary.main"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setColorMode(colorMode === "dark" ? "light" : "dark");
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TundraAppBar;
