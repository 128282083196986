import { GridProgram } from "../../types/GridProgram";
import { Channel } from "../../types/Channel";
import { Affiliate } from "../../types/Affiliate";
import {
  getDatesBetween,
  splitProgramsByDay,
} from "../../utils/splitProgramsByDay";
import splitProgramsByChannel from "../../utils/splitProgramsByChannel";
import markConflictingEvents from "../../utils/markConflictingEvents";
import useTundraStore from "../../store/useTundraStore";
import {
  BlockHeader,
  ItemHeader,
  Items,
} from "../../components/EmissionsCalendar/EmissionsCalendar";
import { Box, Typography } from "@mui/material";
import { getChannelKey } from "../../utils/getChannelKey";
import getChannelLabel from "../../utils/getChannelLabel";
import Tooltip from "../../components/Tooltip/Tooltip";
import Day from "../../components/Day/Day";
import EmissionsWithScale from "../../components/EmissionsCalendar/EmissionsWithScale";
import formatWeekNumber from "../../utils/formatWeekNumber";
import formatDay from "../../utils/formatDay";

const GridByChannel = ({
  programs,
  channels,
}: {
  programs: GridProgram[];
  channels: { channels: Channel[]; affiliates: Affiliate[] };
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const startOfDay = useTundraStore((state) => state.startOfDay);

  const gridDisplay = useTundraStore((state) => state.gridDisplay);
  const baseWidth = 200 + gridDisplay.length * 80;
  const numberOfDates = getDatesBetween(pickerStartDate, pickerEndDate).length;

  const programsByChannel = splitProgramsByChannel(programs, uiChannels);

  const shouldShowBreaks = gridDisplay.includes(1);
  const shouldShowFeeds = gridDisplay.includes(2);

  const programsByChannelByDate = programsByChannel.map(
    ({ channel, programs }) => ({
      channel,
      programsByDay: splitProgramsByDay(
        programs,
        pickerStartDate,
        pickerEndDate,
        startOfDay
      ).map(({ date, programs }) => ({
        date,
        programs: markConflictingEvents(programs),
      })),
    })
  );

  return (
    <EmissionsWithScale
      numberOfItems={programsByChannelByDate.length}
      itemWidth={numberOfDates * baseWidth + 30}
      startOfDay="3am"
    >
      {({ columnIndex, style }) => {
        const { channel, programsByDay } = programsByChannelByDate[columnIndex];
        return (
          <Box marginRight="24px" key={getChannelKey(channel)} style={style}>
            <BlockHeader>
              <Typography fontSize={18} fontWeight="bold">
                {getChannelLabel(channel, channels)}
              </Typography>
            </BlockHeader>
            <Items>
              {programsByDay.map(({ date, programs }) => {
                return (
                  <Box position="relative" width={`${baseWidth}px`} key={date}>
                    <ItemHeader>
                      <Tooltip title={`${formatWeekNumber(date)} week`}>
                        <Typography fontWeight="bold" color="text.primary">
                          {formatDay(date)}
                        </Typography>
                      </Tooltip>
                    </ItemHeader>
                    {gridDisplay.length > 0 && (
                      <Box display="flex" height="24px">
                        <Box width="200px"></Box>
                        {shouldShowBreaks && (
                          <Box width="80px" paddingLeft="3px">
                            <Typography fontSize="14px">Breaks</Typography>
                          </Box>
                        )}
                        {shouldShowFeeds && (
                          <Box width="80px" paddingLeft="3px">
                            <Typography fontSize="14px">Feeds</Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Day
                      programs={programs}
                      startOfDay={startOfDay}
                      date={date}
                      gridDisplay={gridDisplay}
                    />
                  </Box>
                );
              })}
            </Items>
          </Box>
        );
      }}
    </EmissionsWithScale>
  );
};

export default GridByChannel;
