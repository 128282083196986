import { ScheduleProgram } from "../../types/ScheduleProgram";
import { useEffect, useRef, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import getBroadcastUIType from "../../utils/getBroadcastUIType";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import EventIcon from "../EventIcon/EventIcon";
import { styled } from "@mui/material/styles";
import useTundraStore from "../../store/useTundraStore";
import { getEventColorsAndIcon } from "../../utils/getEventColorsAndIcon";
import DecrocheIcon from "../EventIcon/DecrocheIcon";
import MulticamIcon from "../EventIcon/MulticamIcon";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const EventContainer = styled("div")<{
  backgroundColor: string;
  accentColor: string;
  conflictingLevel?: number;
}>`
  border-radius: 4px;
  overflow: hidden;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ accentColor }) => accentColor};
  position: absolute;
  left: ${({ conflictingLevel }) => 1 + (conflictingLevel || 0) * 10}px;
  right: 1px;
  z-index: 10;
`;

const ExpandedEventContainer = styled("div")<{
  accentColor: string;
  conflictingLevel?: number;
}>`
  border-radius: 4px;
  overflow: hidden;
  background: ${({ accentColor }) => accentColor};
  padding: 6px 6px 6px 16px;
  position: absolute;
  top: 0;
  left: ${({ conflictingLevel }) => 1 + (conflictingLevel || 0) * 10}px;
  right: 1px;
  cursor: pointer;
  color: white;
  height: auto !important;
  pointer-events: none;
  z-index: 11;
`;

const EventAccent = styled("div")<{
  accentColor: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: ${({ accentColor }) => accentColor};
`;

const getDisplayableTimeRange = (program: ScheduleProgram) => {
  return (
    formatInTimeZone(program.displayStartDate, "UTC", "HH:mm") +
    "—" +
    formatInTimeZone(program.displayEndDate, "UTC", "HH:mm")
  );
};

type EventProps = {
  program: ScheduleProgram;
  height: number;
  top: number;
  deactivated?: boolean;
};

const BlinkingCircle = styled("div")`
  display: inline-block;
  border-radius: 50%;
  background: #d50032;
  margin-right: 6px;
  margin-top: -2px;
  vertical-align: middle;
  opacity: 1;
  width: 8px;
  height: 8px;
  animation: blink 1s infinite;
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ScheduleEvent = ({
  program,
  height,
  top,
  deactivated,
}: EventProps) => {
  const [expanded, setExpanded] = useState(false);
  const expandedRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  const setSelectedProgram = useTundraStore(
    (state) => state.setSelectedProgram
  );

  const [backgroundColor, accentColor, Icon] = getEventColorsAndIcon(
    getBroadcastUIType(program.broadcastTypeId)
  );

  const isDecroche =
    program.decro !== "Default" && program.channelIsPlayer == false;

  const isEditedRecently = dayjs(program.validationDate).isAfter(
    dayjs(new Date()).tz("Europe/Paris").subtract(1, "day")
  );

  const timeRange = (
    <Typography component="div" fontSize={13}>
      {getDisplayableTimeRange(program)}
    </Typography>
  );

  useEffect(() => {
    setTimeout(() => {
      if (expandedRef.current) {
        const el = expandedRef.current;
        if (
          el.parentElement &&
          el.offsetHeight + el.offsetTop > el.parentElement.clientHeight
        ) {
          setOffset(
            -(el.offsetHeight + el.offsetTop - el.parentElement.clientHeight)
          );
        }
      }
    }, 10);
  }, [expanded, top, height]);

  const validationTooltip = isEditedRecently
    ? `Edited ${dayjs(program.validationDate).tz("Europe/Paris").fromNow()}`
    : null;

  return (
    <>
      <EventContainer
        onClick={() => !deactivated && setSelectedProgram(program)}
        backgroundColor={backgroundColor}
        accentColor={accentColor}
        onMouseEnter={() => !deactivated && setExpanded(true)}
        onMouseLeave={() => !deactivated && setExpanded(false)}
        conflictingLevel={program.conflictingLevel}
        sx={{
          height: `${height}px`,
          top: `${top}px`,
          padding: height > 24 ? "6px 6px 6px 16px" : "0px 6px 6px 16px",
          opacity: deactivated ? "0.2" : null,
          cursor: deactivated ? "default" : "pointer",
        }}
      >
        <Box
          sx={{
            float: "right",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {Icon && height > 24 && (
            <EventIcon
              background={accentColor}
              Icon={Icon}
              tooltip={program.broadcastType}
            />
          )}
          {isDecroche && height > 48 && (
            <EventIcon
              tooltip={program.decro}
              background={accentColor}
              Icon={DecrocheIcon}
            />
          )}
          {program.isMultiCam && height > 48 && (
            <EventIcon
              tooltip="Multi-Cam"
              background={accentColor}
              Icon={MulticamIcon}
            />
          )}
        </Box>

        {height > 50 && (
          <Stack direction="row" alignItems="center">
            {isEditedRecently && (
              <Tooltip title={validationTooltip}>
                <BlinkingCircle sx={{ backgroundColor: accentColor }} />
              </Tooltip>
            )}
            {timeRange}
          </Stack>
        )}
        {height > 10 && (
          <Typography fontWeight="bold" fontSize="14px">
            {isEditedRecently && height <= 50 && (
              <Tooltip title={validationTooltip}>
                <BlinkingCircle sx={{ backgroundColor: accentColor }} />
              </Tooltip>
            )}
            {program.title}
          </Typography>
        )}
        <EventAccent accentColor={accentColor} />
      </EventContainer>
      {expanded && (
        <ExpandedEventContainer
          ref={expandedRef}
          sx={{
            minHeight: height,
            top: top + offset,
          }}
          accentColor={accentColor}
          conflictingLevel={program.conflictingLevel}
        >
          <Box
            sx={{
              float: "right",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            {Icon && (
              <EventIcon background={accentColor} Icon={Icon} isActive />
            )}
            {isDecroche && (
              <EventIcon
                tooltip={program.decro}
                background={accentColor}
                Icon={DecrocheIcon}
                isActive
              />
            )}
            {program.isMultiCam && (
              <EventIcon
                tooltip="Multicam"
                background={accentColor}
                Icon={MulticamIcon}
                isActive
              />
            )}
          </Box>
          <Stack direction="row" alignItems="center">
            {isEditedRecently && (
              <BlinkingCircle sx={{ backgroundColor: "white" }} />
            )}
            <Typography>{timeRange}</Typography>
          </Stack>
          <Typography fontWeight="bold" fontSize="14px">
            {program.title}
          </Typography>
          <Typography component="div" fontSize="14px">
            {program.feature}
          </Typography>
        </ExpandedEventContainer>
      )}
    </>
  );
};
