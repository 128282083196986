import dayjs from "dayjs";

const constructParametersForGridRequest = (
  startDate: string,
  endDate: string,
  channels: number[],
  broadcastTypeIds: number[],
  broadcastId: string,
  valEmiId: string,
  programId: string,
  spevId: string,
  eventIds: number[],
  sportIds: number[],
  competitionIds: number[]
) => {
  const endDateToRequest = dayjs(endDate).add(1, "day").format("YYYY-MM-DD");

  return {
    startDate: `${startDate}T00:00:00.000Z`,
    endDate: `${endDateToRequest}T00:00:00.000Z`,
    channelIds: channels,
    broadcastingTypeIds: broadcastTypeIds,
    spareOfGridIds: [1, 3],
    broadcastId,
    valEmiId,
    programId,
    spevId,
    eventIds: eventIds.join(","),
    sportIds: sportIds.join(","),
    competitionIds: competitionIds.join(","),
  };
};

export default constructParametersForGridRequest;
