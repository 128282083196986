import useTundraStore from "../../store/useTundraStore";
import { ChannelType } from "../../types/ChannelType";
import ExcelExport from "../../components/ExcelExport/ExcelExport";
import constructParametersForGridRequest from "../../utils/constructParametersForGridRequest";

const GRID_EXPORT_API_URL = process.env.REACT_APP_GRID_EXPORT_API as string;

export const GridExcelExport = () => {
  const startDate = useTundraStore((state) => state.pickerStartDate);
  const endDate = useTundraStore((state) => state.pickerEndDate);
  const channels = useTundraStore((state) => state.uiChannels)
    .filter((channel) => channel.type === ChannelType.Channel)
    .map(({ id }) => id);
  const broadcastTypeIds = useTundraStore((state) => state.broadcastTypes);
  const gridBroadcastId = useTundraStore((state) => state.gridBroadcastId);
  const gridValEmiId = useTundraStore((state) => state.gridValEmiId);
  const gridProgramId = useTundraStore((state) => state.gridProgramId);
  const gridSPEVId = useTundraStore((state) => state.gridSPEVId);
  const eventIds = useTundraStore((state) => state.classifiersEvents);
  const sportIds = useTundraStore((state) => state.classifiersSports);
  const competitionIds = useTundraStore(
    (state) => state.classifiersCompetitions
  );

  return (
    <ExcelExport
      url={GRID_EXPORT_API_URL}
      data={constructParametersForGridRequest(
        startDate,
        endDate,
        channels,
        broadcastTypeIds,
        gridBroadcastId,
        gridValEmiId,
        gridProgramId,
        gridSPEVId,
        eventIds,
        sportIds,
        competitionIds
      )}
    />
  );
};
