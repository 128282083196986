import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Alert,
  Box,
  Slider,
  Typography,
  IconButton,
} from "@mui/material";
import useChannels from "../../../hooks/useChannels";
import useSaveChangesSubscription from "../../../hooks/useSaveChangesSubscription";
import { ChangesSubscription } from "../../../types/ChangesSubscription";
import TypeOfChangePicker from "../../../components/TypeOfChangePicker/TypeOfChangePicker";
import BroadcastTypesPicker from "../../../components/BroadcastTypePicker/BroadcastTypesPicker";
import ChannelPicker from "../../../components/ChannelPicker/ChannelPicker";
import { REGULAR_CHANNELS_TABS } from "../../../components/RegularChannelsPicker/RegularChannelsPicker";
import { Close } from "@mui/icons-material";

const AddSubscriptionModal = ({
  onClose,
  onSuccess,
  data,
}: {
  onClose: () => void;
  onSuccess: () => void;
  data: ChangesSubscription;
}) => {
  const [formData, setFormData] = useState<ChangesSubscription>(data);
  const saveChangesMutation = useSaveChangesSubscription();

  const channelsQuery = useChannels();

  if (channelsQuery.isLoading) return <CircularProgress />;

  if (!channelsQuery.data) return <>Error loading Channels</>;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveChangesMutation.mutate(
      {
        ...formData,
      },
      { onSuccess }
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: ChangesSubscription) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Subscription
        <IconButton onClick={onClose}>
          <Close sx={{ color: "primary.main", fontSize: "22px" }} />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ width: 300 }}>
          <>
            {saveChangesMutation.isError && (
              <Alert severity="error">Error saving subscription</Alert>
            )}

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <Box display="flex" gap="24px" alignItems="center">
                <Typography>Range</Typography>
                <Slider
                  size="small"
                  defaultValue={1}
                  valueLabelDisplay="auto"
                  min={0}
                  max={42}
                  value={formData.range}
                  onChange={(e, value) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      range: value as number,
                    }));
                  }}
                />
              </Box>
              <ChannelPicker
                channels={channelsQuery.data.channels}
                channelsSelected={formData.channelIds}
                setSelected={(ids: number[]) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    channelIds: ids,
                  }));
                }}
                label="Channels"
                tabs={REGULAR_CHANNELS_TABS}
              />
              <TypeOfChangePicker
                value={formData.typeOfChangeIds}
                onChange={(ids: number[]) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    typeOfChangeIds: ids,
                  }));
                }}
              />
              <BroadcastTypesPicker
                value={formData.broadcastTypeIds}
                onChange={(ids: number[]) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    broadcastTypeIds: ids,
                  }));
                }}
              />
            </Box>
          </>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button onClick={onClose} color="secondary" variant="text">
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={saveChangesMutation.isLoading}
          >
            {saveChangesMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

const SubscriptionEditor = ({
  onSuccess,
  data,
  onClose,
}: {
  onSuccess: () => void;
  onClose: () => void;
  data: ChangesSubscription;
}) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <AddSubscriptionModal
        onClose={onClose}
        data={data}
        onSuccess={() => {
          onClose();
          onSuccess();
        }}
      />
    </Dialog>
  );
};

export default SubscriptionEditor;
