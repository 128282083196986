import { useQuery } from "@tanstack/react-query";
import { toDate } from "date-fns-tz";
import { GridProgram } from "../types/GridProgram";
import { ProgramType } from "../types/ProgramType";
import constructParametersForGridRequest from "../utils/constructParametersForGridRequest";

const GRID_API_URL = process.env.REACT_APP_GRID_API as string;

const getGridData = (parameters: {}): Promise<GridProgram[]> => {
  return fetch(GRID_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parameters),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      return data.emissions.map(
        ({
          startDate,
          endDate,
          ...data
        }: {
          startDate: string;
          endDate: string;
          title: string;
          emissionDate: string;
        }) => {
          const startDateObject = toDate(startDate, { timeZone: "UTC" });
          const endDateObject = toDate(endDate, { timeZone: "UTC" });
          return {
            startDate: startDateObject,
            endDate: endDateObject,
            displayStartDate: startDateObject,
            displayEndDate: endDateObject,
            type: ProgramType.Grid,
            key: `${startDate}_${data.title}_${data.emissionDate}`,
            ...data,
          };
        }
      ) as GridProgram[];
    })
    .then((programs) =>
      programs.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    );
};

export const useGridData = (
  startDate: string,
  endDate: string,
  channels: number[],
  broadcastTypeIds: number[],
  broadcastId: string,
  valEmiId: string,
  programId: string,
  spevId: string,
  eventIds: number[],
  sportIds: number[],
  competitionIds: number[]
) => {
  return useQuery({
    queryKey: [
      "gridData",
      startDate,
      endDate,
      channels.join(","),
      broadcastTypeIds.join(","),
      broadcastId,
      valEmiId,
      programId,
      spevId,
      eventIds,
      sportIds,
      competitionIds,
    ],
    queryFn: () =>
      getGridData(
        constructParametersForGridRequest(
          startDate,
          endDate,
          channels,
          broadcastTypeIds,
          broadcastId,
          valEmiId,
          programId,
          spevId,
          eventIds,
          sportIds,
          competitionIds
        )
      ),
  });
};
